.transfer-details-container,p,h1,h2,h3,h4,h5 {
  /* padding: 0 4rem; */
  margin: 0;
  padding: 0;

}
.transfer-lead-passenger-details {
  padding: 8px 4rem;
}
.transfer-lead-passenger-details h2 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  color: #1d3071;
  margin-bottom: 1rem;
}
.transfer-lead-passenger-detail {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
}
.lead-passenger-container p {
  color: #a3a8b8;
  font-size: 16px;
  font-weight: 500;
}
.lead-passenger-container h3 {
  color: #1d3071;
  font-size: 20px;
  font-weight: 500;
}
.lead-passenger-container #payment-link {
  background: #56a0f0;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 20px;
  padding: 5px 20px;
  cursor: pointer;
}
.lead-passenger-container #payment-status {
  background: #fff5d9;
  border: none;
  outline: none;
  color: #ffbb38;
  border-radius: 20px;
  padding: 5px 20px;

}
.transfer-details-button {
  background: #ebf2fd;
  padding: 1rem 4rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 2rem;

}
.transfer-details-button #cancel {
  color: #ff4141;
  border: 1px solid #ff4141;
  background: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 20px;

  cursor: pointer;

}
.transfer-details-button #reject {
  background: #ffcccc;
  color: #ff4141;
  border: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.transfer-details-button #on-hold {
  border: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 20px;
  color: #396aff;
  background: #b0d6ff;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
}
.transfer-details-button #approve {
  color: #12b76a;
  background: #bcffe0;
  border: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;

  cursor: pointer;

}
/* ----------------------------------------------------------------------- */
.transfer-reason-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 8, 29, 0.6);
  z-index: 3;
}
.transfer-raeson {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: 50%;
  background: #fff;
  border-radius: 10px;
  padding: 1rem 2rem;
  z-index: 3;
}
.reason-topbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.reason-topbar p {
  color: #1d3071;
  font-size: 20px;
}
.reason-topbar #cencel {
  cursor: pointer;
}
.reason-details textarea {
  outline: none;
  border: 1.5px solid skyblue;
  border-radius: 10px;
  padding: 5px 0px 0px 10px;

  width: 100%;
  height: 4rem;
}
.reason-button button {
  background-color: #2e90fa;
  color: #fff;
  border: none;
  outline: none;
  padding: 7px 20px;
  border-radius: 20px;
  cursor: pointer;

  margin-top: 16px;
}
#send-link-later {
  background: none;
  color: #00081d;
  border: 1px solid #00081d;
  padding: 5px 20px;
  cursor: pointer;
}
.modal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  box-shadow: 0 0 10px #b691a0; 
  padding: 0.5rem 1rem;
  border-radius: 10px;
}
.modal-content select {
  background: none;
  outline: none;
  border: none;
}