.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    border: none !important; 
    background-color: #fff !important; 
}
.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root {
   border: none !important;
}
.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #fff !important;
}
.MuiPagination-ul .css-wjh20t-MuiPagination-ul {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}