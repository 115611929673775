.booking-pkg-custom-details {
    padding: 0 2rem;
}
.booking-pkg-custom-header {
    display: flex;
    justify-content: space-between;
}
.booking-pkg-custom-header img {
    cursor: pointer;
}
.booking-custom-details {
   display: flex;
   padding: 10px 2rem;
   gap: 25px;
   flex-wrap: wrap;
   text-align: center;
}
.custom-booking-total {
    /* height: 60%;
    overflow: auto; */
}
.custom-none {
    display: none;
}
.booking-custom-details-child h4 {
  color: rgba(29, 48, 113, 0.8);   
}
.booking-custom-details-child p {
   color: #A3A8B8;
   font-size: 14px;
} 
.booking-custom-details-child b {
    color: #2552e7;
}