.transfer-form {
    border: 1px solid #dedede;
    box-shadow: 0 5px 15px #dedede;
    padding: 2rem 4rem;
    margin: 2rem;
    font-family: "Montserrat";
    border-radius: 10px;
}
.transfer-form p {
    font-weight: 600;
    color: #38393b;
    margin-bottom: 6px;
}
.step-by-form-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}
#step1 h2 {
    font-size: 20px;
    color: #38393b;
}
#step2 h2 {
    font-size: 20px;
    color: #38393b;
}
.transfer-form-country-city-type span {
    display: none;
} 
.transfer-form-country-city-type {
    width: 100%;
    display: flex;
    gap: 4rem;
    justify-content: space-between;
}

.transfer-form-country-parent {
    width: 100%;
}
.transfer-form-country {
    background-color:rgba(192, 192, 192, 0.2);
    border-radius: 20px;
    padding:10px 2rem;
    border: 1.5px solid silver;
    width: 100%;
}
.transfer-form-country select {
    width: 100%;
    border-radius: 20px;
    background-color:rgba(192, 192, 192, 0);
    outline: none;
    border: none;
    font-size: 1.2rem;
}
.transfer-form-country select option {
    border-radius: 20px;
    font-size: 1.5rem;
    color: #00081d;
    width: 100%;
}

.pick-up-drop-up-duration-parent {
    display: flex;
    width: 100%;
    gap: 4rem;
    padding-top: 2rem;
}
.pick-up-drop-up-duration {
    display: flex;
    width: 70%;
    gap: 4rem;
}
.pick-up-drop-up-duration .pick-up-parent {
    width: 100%;
}
.pick-up-parent .pick-up {
    background-color:rgba(192, 192, 192, 0.2);
    border-radius: 20px;
    padding:10px 2rem;
    border: 1.5px solid silver;
}
.pick-up-parent .pick-up select {
    width: 100%;
    border-radius: 20px;
    background-color:rgba(192, 192, 192, 0);
    outline: none;
    border: none;
    font-size: 1.2rem;
}
.durration {
    width: 30%;
}
.durration .durration-Input {
    background-color:rgba(192, 192, 192, 0.2);
    border-radius: 20px;
    padding:10px;
    border: 1.5px solid silver;
    width: 100%;
}
.durration-parent {
    display: flex;
    gap: 2rem;
    width: 100%;
}
.durration .durration-Input input {
    background: none;
    outline: none;
    border: none;
    width: 100%;
}
.transfer-form-back-next-btn {
    padding-top: 2rem;
    display: flex;
    gap: 2rem;
    font-family: "Montserrat";
}
.back-btn button {
    font-weight: 500;
    background: none;
    border: none;
    outline: none;
    padding:8px 1rem;
    cursor: pointer;
}
.next-btn button {
    font-weight: 500;
    background: #52CCFC;
    border: none;
    outline: none;
    border-radius: 10px;
    padding:8px 20px;
    cursor: pointer;
}
/* --------------------Second Page---------- */
.transfer-form-country input {
    border: none;
    outline: none;
    background: none;
}
.transfer-form-country select option {
    font-size: 18px;
}
.select-vehicle {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.extra-details-of-transfer {
    margin-top: 1rem;
}

.extra-details-of-transfer h2 {
    margin-bottom: 0;
}
.extra-price-addtional-details {
    display: flex;
    gap: 4rem;
}
.price-of-transfer {
 width: 50%;
}
.price-currency #select {
    outline: none;
    width: 30%;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(192, 192, 192, 0.2);
}
.price-currency input {
    border-radius: 10px;
    outline: none;
    border: 1.5px solid silver;
    padding: 10px;
}
.additional-details {
    width: 50%;
}
.additional-details textarea {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: 1.5px solid silver;
}

.country-city-type {
    display: flex;
    justify-content: space-between;
    margin-bottom:0.5rem ;
}
.country-city-type h4 {
    font-weight: 600;
    color: #00081d;
    margin: 0;
}
.country-city-type span {
    font-weight: 500;
    color: #00081d;
    opacity: 0.8;
}
.region-details h2{
    font-weight: 600;
    color: #00081d;
    margin-bottom: 16px;
}
/* ------------------------------TransferList CSS---------------- */
#trnasfer-action-btn {
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    font-size: 18px;
    color: blue;
}
/* ---------------------------------Transfer Details ------------- */
.transfer-detail-region {
  padding: 1rem 2rem;
}
.transfer-detail-region h2 {
    color: #00081D;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}
.transfer-detail-region-value {
  padding: 10px 0;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}
.transfer-detail-region-info {
   display: flex;
   gap: 5px;
   align-items: center;
}
.transfer-detail-region-info h4 {
    color: #00081d;
    font-size: 16px;
    font-weight: 500;
}
.transfer-detail-region-info p {
    color: #848484;
    font-size: 14px;
}