.add-vehicle-form-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 8, 29, 0.6);
    overflow: auto;
}

.vehicle-add-form {
    background: #fff;
    /* border-bottom: 1px solid #dedede; */
    width: fit-content;
    padding: 2rem;
    box-shadow: 0 0 10px #3d3737;
    margin: auto;
    border-radius: 10px;
}

.vehicle-add-form h2 {
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
    color: #0d716d;
}

.vehicle-name-maxpass {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.vehicle-add-form label {
    display: block;
    margin-bottom: 5px;
    width: 220px;
}

.vehicle-add-form input {
    width: 100%;
    border: 1px solid skyblue;
    outline: none;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 1rem;
}

.vehicle-add-button {
    display: flex;
    justify-content: space-between;
    /* text-align: center; */
    padding: 2rem 0 0 0;
}

.vehicle-add-button #cancel {
    background: none;
    border: 1.5px solid #00081d;
    padding: 6px 20px;
    border-radius: 20px;
    outline: none;
    /* border: none; */
    color: #00081d;
    font-weight: 500;
    cursor: pointer;
}

.vehicle-add-button #addvehicle {
    background: #52CCFC;
    padding: 10px 20px;
    border-radius: 20px;
    outline: none;
    border: none;
    color: #00081d;
    font-weight: 500;
    cursor: pointer;
}

/* --------------------- */
.file-input {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.file-input::-webkit-file-upload-button {
    display: none;
}
.vehicle-name {
    width: 100%;
}
.vehicle-name .react-tel-input .form-control {
    width: 100%;
    border-radius: 20px;
    border: 1px solid skyblue;
}

.vehicle-name .react-tel-input .flag-dropdown {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

/* ----------------------------------Vehicle---------------------- */
.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.add-hotel {
    background: #2E90FA;
    padding: 5px 20px;
    border-radius: 10px;
    outline: none;
    border: none;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    font-family: "Montserrat";
}